import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as store from '../../app.state';
import * as preadmissionAction from './actions/preadmissionlist.action';
import { Subscription } from 'rxjs/index';
import { preadmissionlist
       , preadmissionlistloading
       , renewalDetails
       , renewalDetailsLoading
       ,admissionPaymentDetailsLoading
       ,admissionPaymentDetails } from './reducer/preadmissionlist.selector';
import { LoginModel } from './model/preadmissionlist.model';

@Injectable()
export class preadmissionlistSandbox {
  public preadmissionlist$ = this.appState.select(preadmissionlist);
  public preadmissionlistloading$ = this.appState.select(preadmissionlistloading);
  public renewalDetails$ = this.appState.select(renewalDetails);
  public renewalDetailsLoading$ = this.appState.select(renewalDetailsLoading);

  public admissionPaymentDetails$ = this.appState.select(admissionPaymentDetails);
  public admissionPaymentDetailsLoading$ = this.appState.select(admissionPaymentDetailsLoading);

  private subscriptions: Array<Subscription> = [];

  constructor(protected appState: Store<store.AppState>) {
  }

  public preadmissionlist(params) {
    this.appState.dispatch(new preadmissionAction.preadmissionlist(params));
  }

  public updateApplicationPaymentStatus(params) {
    this.appState.dispatch(new preadmissionAction.updateApplicationPaymentStatus(params));
  }
  public admissionPaymentDetails(params) {
    this.appState.dispatch(new preadmissionAction.admissionPaymentDetails(params));
  }
  // public getRenewalDetails(value) {
  //   this.appState.dispatch(new dashBoardActions.RenewalDetails(value));
  // }
}
