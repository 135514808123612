import {createSelector} from 'reselect';

import * as PreadmissionListreducer from './preadmissionlist.reducer';
import {AppState} from '../../../app.state';

// *************************** PUBLIC API's ****************************
/**
 * Signup store functions
 */
export const getSubState = (state: AppState) => state.PreadmissionListState;
export const preadmissionlist = createSelector(getSubState, PreadmissionListreducer.preadmissionlist);
export const preadmissionlistloading = createSelector(getSubState, PreadmissionListreducer.preadmissionlistLoading);
export const  preadmissionlistLoaded = createSelector(getSubState, PreadmissionListreducer.preadmissionlistLoaded);
export const  preadmissionlistFailed = createSelector(getSubState, PreadmissionListreducer.preadmissionlistFailed);

export const renewalDetails = createSelector(getSubState, PreadmissionListreducer.renewalDetails);
export const renewalDetailsLoading = createSelector(getSubState, PreadmissionListreducer.renewalDetailsLoading);
export const renewalDetailsLoaded = createSelector(getSubState, PreadmissionListreducer.renewalDetailsLoaded);
export const renewalDetailsFailed = createSelector(getSubState, PreadmissionListreducer.renewalDetailsFailed);

export const admissionPaymentDetails = createSelector(getSubState, PreadmissionListreducer.admissionPaymentDetails);
export const admissionPaymentDetailsLoading = createSelector(getSubState, PreadmissionListreducer.admissionPaymentDetailsLoading);
