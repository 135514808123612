import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseInterface } from '../../shared/interfaces/interface';
import { Api } from '../../providers/api/api';

@Injectable()

export class preadmissionlistService extends Api {

  private URL: string = this.getBaseUrl();

  preadmissionlist(params) {
    const id = params.mobileno;
    return this.http.get<ResponseInterface>(this.URL + '/pre-admission/GetPreadmissionformlist' , {params: params});
   // return this.http.get(this.URL + '/pre-admission/GetPreadmissionformlist/+ id' );
  }
  // getRenewalDetails(val): Observable<ResponseInterface> {
  //   return this.http.get<ResponseInterface>(this.URL + '/dashboard/renewal-details');
  // }

  updateApplicationPaymentStatus(params) {
    return this.http.get<ResponseInterface>(this.URL + '/pre-admission/update-appication-form-payment-status' , {params: params});
  }

  admissionPaymentDetails(params): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL + '/jasper/onlineadmission-certificate-form', params);
  }
}
